import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/MainLayout"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/site/home"),
      },
      {
        path: "/diktant",
        name: "diktant",
        component: () => import("../views/site/dictionaries"),
      },
      {
        path: "/insho",
        name: "insho",
        component: () => import("../views/site/essey"),
      },
      {
        path: "/bayon",
        name: "bayon",
        component: () => import("../views/site/statement"),
      },
      {
        path: "/audio",
        name: "audio",
        component: () => import("../views/site/multimedia/audio"),
      },
      {
        path: "/video",
        name: "video",
        component: () => import("../views/site/multimedia/video"),
      },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/site/about"),
      },
      {
        path: "/detailed-page/:dictation_id",
        name: "detailed-dictation",
        component: () => import("../views/site/detailed-dictation"),
      },
      {
        path: "/detailed-essey/:essey_id",
        name: "detailed-essey",
        component: () => import("../views/site/detailed-essey"),
      },
      {
        path: "/detailed-statement/:statement_id",
        name: "detailed-statement",
        component: () => import("../views/site/detailed-statement"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
